import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { Mail, MapPin, Phone } from 'react-feather'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  phone?: string
  title?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  languageCode,
  phone,
  title,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Contacts dial={2} row wrap>
        {address ? (
          <Address>
            <MapPin />
            <Wrap>
              <Label>{useVocabularyData('address', languageCode)}</Label>
              <Field dangerouslySetInnerHTML={{ __html: address }} />
            </Wrap>
          </Address>
        ) : null}
        {phone ? (
          <Anchor
            href={`tel:${phone}`}
            onClick={() => {
              if (isMobile) {
                typeof window.gtag !== 'undefined' &&
                  window.gtag('event', 'Click', {
                    event_category: 'Website',
                    event_label: 'Mobile Phone',
                  })
              }
            }}
          >
            <Phone />
            <Wrap>
              <Label>{useVocabularyData('telephone-fax', languageCode)}</Label>
              <Field className="contact-field">{phone}</Field>
            </Wrap>
          </Anchor>
        ) : null}
        {email ? (
          <Anchor
            href={`tel:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            <Mail />
            <Wrap>
              <Label>{useVocabularyData('email', languageCode)}</Label>
              <Field className="contact-field">{email}</Field>
            </Wrap>
          </Anchor>
        ) : null}
      </Contacts>
    </Container>
  )
})

const Container = styled.section`
  max-width: 62.1875rem;
  border: 2px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-bottom: 0;
  margin: 6.75rem auto 0;
  padding: 3.75rem 1.875rem 3.75rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 6rem;
    padding: 3rem 1.5rem 3rem;
  }

  @media (max-width: 767px) {
    border: 0;
    margin-top: 3.75rem;
    padding: 0 1.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;
  margin-bottom: 1.75rem;
`

const Contacts = styled(FlexBox)``

const Style = css`
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  text-align: left;

  svg {
    width: auto;
    height: 2.125rem;
    margin-right: 1.25rem;
    stroke-width: 1;
    stroke: ${theme.colors.variants.primaryDark1};
  }

  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
    margin-top: 1.5rem;
    text-align: center;
    svg {
      display: none;
    }
  }
`

const Address = styled.div`
  ${Style}

  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Anchor = styled.a`
  &:hover {
    .contact-field {
      text-decoration: underline;
    }
  }

  ${Style}
`

const Wrap = styled.span``

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Field = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 28px;
`
