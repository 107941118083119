import axios, { AxiosError } from 'axios'
import { Either, left, right } from 'fp-ts/Either'

export interface FormData {
  name: string
  lastname: string
  email: string
  phone?: string
  arrival_date: string
  departure_date: string
  guests?: string
  message?: string
  offer_title?: string
  privacy_policy: boolean
}

export interface ContactsFormSenderBackendConfiguration {
  formURL: string
}

export type ContactsFormSenderBackend = (
  data: FormData,
) => Promise<Either<AxiosError, void>>

export const ContactsFormSenderBackend =
  ({
    formURL,
  }: ContactsFormSenderBackendConfiguration): ContactsFormSenderBackend =>
  async (data): Promise<Either<AxiosError, void>> => {
    try {
      await axios({
        method: 'POST',
        url: formURL,
        headers: {
          'Content-type': 'application/json',
        },
        data,
      })
      return right(undefined)
    } catch (error) {
      return left(error as any)
    }
  }

export interface ContactsFormSenderSlackConfiguration {
  webhookURL: string
}

export type ContactsFormSenderSlack = (input: {
  data: FormData
  backendError?: AxiosError
}) => Promise<Either<AxiosError, void>>
